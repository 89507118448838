<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Empresas</h1>
		</div>
		<br />

		<DataGrid :configuracion="dataGridConfig" :data="empresas" @seleccionadas="dataGridActions" :seleccionar="seleccionar">
			<div class="row">
				<div class="col-md-2">
					<button class="btn warning-btn" @click="obtener_empresas">Recargar</button>
				</div>
				<div v-if="$auth.can('formalizacion', 'crear_empresas')" class="col-md-2">
					<button class="btn principal-btn" @click="cargar_modal(1)">Nuevo</button>
				</div>
				<div v-if="$auth.can('formalizacion', 'editar_empresas')" class="col-sm-2">
					<button class="btn secondary-btn" @click="cargar_modal(2)">Editar</button>
				</div>
			</div>
		</DataGrid>

		<Modal v-if="agregar_empresa!=0" @close="cancelar_empresa" class="bpb-modal">
			<h2 class="title">Empresa</h2>
			<div class="body">
				<div class="row form-group">
					<label for="empresa" class="col-form-label col-sm-2">Empresa</label>
					<div class="col-sm-10"><input v-model="empresa.empresa" type="text" name="empresa" id="empresa" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="descripcion" class="col-form-label col-sm-2">Descripción</label>
					<div class="col-sm-10"><input v-model="empresa.descripcion" type="text" name="descripcion" id="descripcion" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="" class="col-form-label col-sm-2">Oficinas</label>
					<div class="col-sm-10 list-oficinas">
						<div class="container-fluid">
							<div class="row list-title">
								<div class="col-sm-3">Nombre</div>
								<div class="col-sm-5">Dirección</div>
								<div class="col-sm-3">Teléfono</div>
							</div>
							<div v-for="tmpOffice in oficinas" class="row list-item">
								<div class="col-sm-3">{{ tmpOffice.nombre }}</div>
								<div class="col-sm-5">{{ tmpOffice.direccion }}</div>
								<div class="col-sm-3">{{ tmpOffice.telefono }}</div>
								<div class="col-sm-1">
									<img src="https://static.bienparabien.com/constelacion/img/bin.png" @click="quitar_oficina(tmpOffice.id)" alt="Quitar" title="Quitar oficina">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-sm-3">
							<button class="btn secondary-btn" @click="ver_oficina = true">Agregar oficina</button>
						</div>
						<div class="col-sm-3">
							<button class="btn principal-btn" @click="guardar_empresa">Guardar</button>
						</div>
						<div class="col-sm-3">
							<button class="btn complementary-btn" @click="cancelar_empresa">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="ver_oficina" :options="{width: '40vw'}" @close="cancelar_oficina" class="bpb-modal">
			<h2 class="title">Nueva oficina</h2>
			<div class="body">
				<div class="row form-group">
					<label for="nombre" class="col-form-label col-sm-2">Nombre</label>
					<div class="col-sm-10"><input v-model="oficina.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="direccion" class="col-form-label col-sm-2">Dirección</label>
					<div class="col-sm-10"><input v-model="oficina.direccion" type="text" name="direccion" id="direccion" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="telefono" class="col-form-label col-sm-2">Teléfono</label>
					<div class="col-sm-10"><input v-model="oficina.telefono" type="text" name="telefono" id="telefono" class="form-control"></div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-sm-4">
							<button class="btn principal-btn mr-2" @click="agrega_oficina">Agregar</button>
						</div>
						<div class="col-sm-4">
							<button class="btn complementary-btn" @click="cancelar_oficina">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import Modal from '@/components/Modal'
	import DataGrid from '@/components/DataGridV3'

	import api from '@/apps/formalizacion/api/v1/formalizacion'

	export default {
		components: {
			Modal, DataGrid
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'empresas'
					,cols: {
						id: 'ID'
						,empresa: 'Empresa'
						,descripcion: 'Descripción'
						,estatus: 'Estatus'
					}
					,mutators: {
						tipo: function(val) {
							return val == 1 ? 'Notario' : 'Valuador';
						}
						,estatus: function(val) {
							return val == 1 ? 'Activo' : 'Inactivo';
						}
					}
				}
				,seleccionar: false
				,seleccion: []
				,agregar_empresa: 0
				,empresas: []
				,empresa: {
					empresa: null
					,descripcion: null
				}
				,oficinas: []
				,oficinas_originales: []
				,oficina: {
					nombre: null
					,direccion: null
					,telefono: null
				}
				,ver_oficina: false
			}
		}
		,mounted: function() {
			this.obtener_empresas();
		}
		,methods: {
			//Modal empresas
			obtener_empresas: async function() {
				this.empresas = (await api.obtener_empresas(this.options)).data;
			}
			,obtener_oficinas: async function() {
				this.oficinas = (await api.obtener_empresas_oficinas(this.empresa.id)).data;
				this.oficinas_originales = Array.from(this.oficinas);
			}
			,dataGridActions: function(valor) {
				this.seleccion = valor;
				this.seleccionar = true;
			}

			//Modal
			,cargar_modal: function(tipo) {
				if (tipo == 1)
					this.agregar_empresa = 1;
				else
					if (this.seleccion.length > 0) {
						this.empresa = this.seleccion[0];
						this.obtener_oficinas();
						this.agregar_empresa = 2;
					}else
						this.$helper.showMessage('Error','Tienes que seleccionar la empresa a editar', 'error', 'alert');
			}
			,cancelar_empresa: function() {
				this.empresa = {
					empresa: null
					,descripcion: null
				}
				this.oficinas = [];
				this.oficinas_originales = [];
				this.agregar_empresa = 0;
				this.seleccionar = false;
			}
			,guardar_empresa: async function() {
				try {
					if (this.agregar_empresa == 1 && !this.empresa.id) {
						this.empresa = (await api.crear_empresa(this.empresa)).data;
					}else {
						this.empresa = (await api.actualizar_empresa(this.empresa.id, this.empresa)).data;
					}

					let nuevas = [], borradas = [];
					this.$log.info('oficinas', this.oficinas);
					this.$log.info('oficinas org', this.oficinas_originales);

					for(let i=0; i<this.oficinas.length; i++) {
						let encontrada = false;

						for(let a=0; a<this.oficinas_originales.length; a++) {
							if (this.oficinas[i].id == this.oficinas_originales[a].id) {
								encontrada = true;
								a = this.oficinas_originales.length;
							}
						}

						if (!encontrada)
							nuevas.push(this.oficinas[i]);
					}

					for(let i=0; i<this.oficinas_originales.length; i++) {
						let encontrada = false;

						for(let a=0; a<this.oficinas.length; a++) {
							if (this.oficinas_originales[i].id == this.oficinas[a].id) {
								encontrada = true;
								a = this.oficinas.length;
							}
						}

						if (!encontrada)
							borradas.push(this.oficinas_originales[i]);
					}

					this.$log.info('nuevas', nuevas);
					this.$log.info('borradas', borradas);

					if (nuevas.length > 0) {
						for(let i=0; i<nuevas.length; i++) {
							if (nuevas[i].telefono == null)
								delete nuevas[i].telefono;

							await api.crear_oficina(this.empresa.id, nuevas[i]);
						}
					}

					if (borradas.length > 0) {
						for(let i=0; i<borradas.length; i++) {
							await api.eliminar_oficina(this.empresa.id, borradas[i].id);
						}
					}

					this.seleccionar = false;
					this.cancelar_empresa();
					this.obtener_empresas();
				}catch(e) {
					this.$log.info('error', e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}

			//oficinas
			,cancelar_oficina: function() {
				this.oficina = {
					nombre: null
					,direccion: null
					,telefono: null
				};
				this.ver_oficina = false;
				this.seleccionar = false;
			}
			,agrega_oficina: function() {
				// if (!this.oficina.id)
				// 	this.oficina.id = this.oficinas.length;

				this.oficinas.push(this.oficina);

				this.cancelar_oficina();
			}
			,quitar_oficina: function(id) {
				let oficinas = [];

				for(let i=0; i<this.oficinas.length; i++) {
					if (this.oficinas[i].id != id)
						oficinas.push(this.oficinas[i]);
				}

				this.oficinas = oficinas;
			}
		}
	}
</script>

<style lang="scss">
	.list-oficinas {
		.list-title {
			background-color: #2281CD;
			color: #fff;
		}

		.list-item:nth-child(2n+1) {
			background-color: #e5e5e5;
		}

		img {
			width: 15px;
		}
	}
</style>